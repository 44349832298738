import {
  Attachment,
  AttType,
  CpdTypeInput,
  useSaveHighlevelRequirementMutation,
  SaveHighlevelRequirementMutationOptions,
  HighLevelRequirementDetail
} from 'src/graphql/generated';
import { AttachmentsArray, HlrFormData } from 'src/components/Pages/HLR/types';
import { useContext, useState } from 'react';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { prepareAttachments } from 'src/components/Attachments/utils';
import { useUploadAttachments } from 'src/hooks/useUploadAttachments';

export const useSubmitHlrUpload = ({
  hlrDetails,
  options
}: {
  hlrDetails: HighLevelRequirementDetail;
  options: SaveHighlevelRequirementMutationOptions;
}) => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const [fileUploadError, setFileUploadError] = useState<string | undefined>();
  const [uploadingFiles, setUploadingFiles] = useState(false);

  const uploadFiles = useUploadAttachments(AttType.Hlrattachments);

  const [saveHlrDetailsMutation, { loading: saveHlrLoading, error: saveHlrError, ...rest }] =
    useSaveHighlevelRequirementMutation(options);

  return {
    loading: saveHlrLoading || uploadingFiles,
    error: saveHlrError || fileUploadError,
    ...rest,
    submit: async (data: HlrFormData) => {
      setFileUploadError(undefined);
      const localFiles = data.attachments.filter((a): a is File => a instanceof File);
      const previousAttachments = data.attachments.filter(
        (a): a is Attachment => !(a instanceof File)
      );

      let newAttachments: Attachment[] = [];
      if (localFiles?.length > 0) {
        try {
          setUploadingFiles(true);
          newAttachments = (await uploadFiles(localFiles)) as AttachmentsArray;
        } catch (e) {
          setFileUploadError('Failed to upload files, please try again later.');
          return;
        } finally {
          setUploadingFiles(false);
        }
      }

      const aggregatedAttachments = [
        ...prepareAttachments(previousAttachments),
        ...prepareAttachments(newAttachments)
      ];

      const { eaHours, rpHours, moHours } = data;
      console.log(data);
      let cpd_types: Array<CpdTypeInput> = [
        { type: 'EA', hours: eaHours },
        { type: 'RP', hours: rpHours },
        { type: 'MO', hours: moHours }
      ];
      cpd_types = cpd_types.filter((cpdTypeInput) => cpdTypeInput.hours && cpdTypeInput.hours > 0);

      return saveHlrDetailsMutation({
        variables: {
          memberId: activeMemberId,
          hlrDetails: {
            activitykey: hlrDetails?.sortkey,
            activity_type_id: hlrDetails.activity_type_id,
            activity_type_code: hlrDetails.activity_type!,
            title: hlrDetails.title!,
            start_date: hlrDetails.start_date,
            end_date: hlrDetails.end_date
          },
          attachments: aggregatedAttachments,
          cpdTypes: cpd_types
        }
      });
    }
  };
};
