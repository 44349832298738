import React from 'react';
import {
  Typography,
  Button,
  Box,
  Drawer,
  Container,
  useMediaQuery,
  useTheme,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import { DownloadStatementButton } from './DownloadStatementButton';

export const CpdStatementPanel = () => {
  const CPDLink20To22 = import.meta.env.VITE_2022_CPD_LINK!;
  const CPDLink17AndPrior = import.meta.env.VITE_2017_PRIOR_CPD_LINK!;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openDrawer, setOpenDrawer] = React.useState(false);

  return (
    <Box>
      <Button
        variant="outlined"
        fullWidth
        href=""
        target="_blank"
        sx={{
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'flex-end',
          justifyContent: 'flex-start',
          borderRadius: '8px',
          borderColor: '#D2DCE1',
          backgroundColor: '#EAEDEF',
          height: '40px'
        }}
        onClick={() => setOpenDrawer(true)}
      >
        <Typography
          variant="caption"
          sx={{ display: { xs: 'none', sm: 'block' }, fontSize: '14px', fontWeight: '500' }}
        >
          View&nbsp;
        </Typography>
        CPD Statement
        <ChevronRightOutlinedIcon sx={{ ml: 0.5 }} fontSize="small" />
      </Button>

      <Drawer
        variant="temporary"
        open={openDrawer}
        anchor={'right'}
        onClose={() => setOpenDrawer(false)}
      >
        <Container sx={{ pb: 5, pt: 3 }} maxWidth={'sm'}>
          <Box display={'flex'} mb={2} sx={{ alignItems: 'baseline' }}>
            <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ color: 'primary.main', flex: 1 }}>
              CPD Statements
            </Typography>
            <CloseIcon cursor={'pointer'} onClick={() => setOpenDrawer(false)} />
          </Box>
          <Box mt={3}>
            <Typography sx={{ mb: 2 }}>
              Here you can access current and previous CPD statements and dashboards.
            </Typography>
            <Typography>By visiting your previous dashboards, you have access to:</Typography>
            <Typography component="ul" sx={{ my: 2 }}>
              <Typography component="li">Historic CPD statements</Typography>
              <Typography component="li">Triennial certificates of completion</Typography>
            </Typography>
          </Box>
          <Divider sx={{ mt: 3 }} />

          <Box>
            <Typography variant="h3" sx={{ color: 'primary.main', flex: 1, my: 2 }}>
              CPD Statements
            </Typography>
            <DownloadStatementButton buttonText="Download CPD Statement 2025" cycleName="2025" />
            <DownloadStatementButton buttonText="Download CPD Statement 2024" cycleName="2024" />
            <DownloadStatementButton buttonText="Download CPD Statement 2023" cycleName="2023" />
          </Box>

          <Divider />
          <Box display={'flex'} flexWrap={'wrap'}>
            <Typography variant="h3" sx={{ color: 'primary.main', flex: 1, mt: 2 }}>
              Previous Trienniums
            </Typography>
            <Button
              variant="outlined"
              fullWidth
              href={CPDLink20To22}
              sx={{
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'flex-end',
                justifyContent: 'flex-start',
                borderRadius: '8px',
                my: 2,
                fontWeight: 600,
                py: 1.5
              }}
            >
              <DescriptionOutlinedIcon sx={{ mr: 1, color: 'secondary.main' }} />
              myCPD 2020-22 Dashboard
              <ChevronRightOutlinedIcon sx={{ ml: 1, marginLeft: 'auto' }} />
            </Button>
            <Button
              variant="outlined"
              fullWidth
              href={CPDLink17AndPrior}
              sx={{
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'flex-end',
                justifyContent: 'flex-start',
                borderRadius: '8px',
                fontWeight: 600,
                py: 1.5
              }}
            >
              <DescriptionOutlinedIcon sx={{ mr: 1, color: 'secondary.main' }} />
              myCPD 17-19 Dashboard and prior
              <ChevronRightOutlinedIcon sx={{ ml: 1, marginLeft: 'auto' }} />
            </Button>
          </Box>
        </Container>
      </Drawer>
    </Box>
  );
};
