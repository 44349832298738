import { useContext } from 'react';
import { Alert, AlertTitle, Box, Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  useGetMemberHlrDetailsByKeyQuery,
  HighLevelRequirementDetail
} from 'src/graphql/generated';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';
import { HLRForm } from './HLRForm';
import { LoadableBox } from 'src/components/Loadable/LoadableBox';

const HlrPage = () => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const { activitykey } = useParams<{ activitykey: string }>();
  const decodedActivityKey = decodeURIComponent(activitykey);

  const {
    data,
    loading,
    error: apiError,
    called
  } = useGetMemberHlrDetailsByKeyQuery({
    variables: { memberId: activeMemberId, activitykey: decodedActivityKey },
    fetchPolicy: 'no-cache'
  });

  const activeHlrDetails = data?.getMemberHlrDetailsByKey as HighLevelRequirementDetail;

  const hasNotFoundError = called && !loading && activeHlrDetails === null;
  if (apiError || hasNotFoundError) {
    let errorMessage = apiError?.message ?? `Dual Fellowship for ${activeMemberId} is not found`; // this should be a backend "NOTFOUND" error but this is returning null
    return (
      <>
        <ContentPageTitle currentPageTitle={'Error'} showImpersonate={true} />
        <Container maxWidth="lg">
          <Box my={2}>
            <Alert severity="error">
              <AlertTitle>{errorMessage}</AlertTitle>
            </Alert>
          </Box>
        </Container>
      </>
    );
  }

  return (
    <>
      <ContentPageTitle currentPageTitle={activeHlrDetails?.title!} showImpersonate={true} />
      <Container maxWidth="lg">
        <LoadableBox loading={loading}>
          <HLRForm hlrCardDetails={activeHlrDetails} />
        </LoadableBox>
      </Container>
    </>
  );
};
export { HlrPage };
