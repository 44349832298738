import { useState } from 'react';
import { format } from 'date-fns';
import _ from 'lodash';
import { formatInTimeZone } from 'date-fns-tz';

import { Cycle } from 'src/graphql/generated';

const FALLBACK_CYCLE = '2024';
const VALID_CYCLES = ['2025', '2024', '2023'];

const getInitialActiveCycle = (cycles: string[]) => {
  const currentYear = format(new Date(), 'yyyy');
  const cycle = cycles.find((s) => s === currentYear);
  return cycle || FALLBACK_CYCLE;
};

// Sample:
// {
//   close_date: "2025-12-31T12:59:59.999Z",
//   end_date: "2025-12-31T12:59:59.999Z",
//   name: "2025",
//   start_date: "2024-12-31T13:00:00.000Z"
// }

/// Mock method as we don't have time to change this to use API right now
const getSelectedCycleDetails = (activeCycle: string) => {
  const cycleYear = Number(activeCycle);
  const selectedCycle = {
    name: activeCycle,
    start_date: `${cycleYear - 1}-12-31T13:00:01.000Z`, // added .01 seconds because backend logic is bad :(
    end_date: `${activeCycle}-12-31T12:59:59.999Z`,
    close_date: `${activeCycle}-12-31T12:59:59.999Z` // note close_date is wrong
  } as Cycle;
  return selectedCycle;
};

///
const getCycleDate = (activeCycle: string) => {
  let cycleDate = new Date();
  const currentYear = new Date().getFullYear().toString();
  const activeCycleDetails = getSelectedCycleDetails(activeCycle);

  if (activeCycleDetails.name < currentYear) {
    /// if the active year is in the past => pick the last date of the past cycle
    cycleDate = new Date(activeCycleDetails.end_date);
  }
  ///
  else if (activeCycle === currentYear) {
    // Current date => leave as new Date()
  }
  /// if the active year is in the future => pick the first date of the future cycle
  else if (currentYear < activeCycleDetails.name) {
    cycleDate = new Date(activeCycleDetails.start_date);
  }

  return cycleDate;
};

export const useCycles = (): {
  cyclesList: string[];
  activeCycle: string;
  setActiveCycle: (newCycle: string) => void;
  cycleDate: Date;
  cycleJustDate: string;
} => {
  const cyclesList = VALID_CYCLES;
  const [activeCycle, setActiveCycle] = useState(getInitialActiveCycle(cyclesList));
  // todo: replace the cycles list to be entirely backend driven
  const cycleDate = getCycleDate(activeCycle);
  const cycleJustDate = formatInTimeZone(
    getCycleDate(activeCycle),
    'Australia/Melbourne',
    'yyyy-MM-dd'
  );

  return {
    cyclesList: cyclesList,
    activeCycle,
    setActiveCycle,
    cycleDate,
    cycleJustDate
  };
};
