import { Typography } from '@mui/material';
type Slide1Props = {
  setClose: () => void;
};
const Slide1 = ({ setClose }: Slide1Props) => {
  return (
    <>
      <Typography variant="h2">Welcome to your 2025 myCPD Home</Typography>
      <Typography variant="h5">Plan for a successful CPD year</Typography>
      <Typography variant="body2">
        If your 2024 CPD plan worked well for you, feel free to use the elements that were most
        useful again for 2025. We encourage you to use the RACGP PDP tool to start planning your CPD
        activities for the year ahead.
      </Typography>
      <Typography variant="h5">Still recording your 2024 CPD?</Typography>
      <Typography variant="body2">
        You have until 28 February to finalise your 2024 CPD recording. Remember to log your
        activities using 2024 dates so they appear in the year they’re needed.
      </Typography>
      <Typography
        component="a"
        sx={{
          mb: 2,
          textDecoration: 'none',
          border: '1px solid rgb(210, 220, 225)',
          borderRadius: '25px',
          py: 1,
          px: 2,
          width: 'fit-content'
        }}
        onClick={setClose}
        href={import.meta.env.VITE_POPUP_BUTTON_LINK}
        rel="noreferrer"
      >
        Start your 2025 PDP
      </Typography>
    </>
  );
};
export { Slide1 };
