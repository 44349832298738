import * as React from 'react';
import { Box, MobileStepper, Button, Dialog } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from '@mui/icons-material/Close';
import { Slide1 } from './Slide1';
import { compare } from 'compare-versions';

const WhatsNewPopup = () => {
  /* IMPORTANT: Will need to update the gitTagVersion number When new features are added or updated  */
  const gitTagVersion = '4.0.147';

  const newFeaturesVersion: string = gitTagVersion!;
  const showPopup: boolean = compare('0.0.000', newFeaturesVersion, '<');
  const [open, setOpen] = React.useState(false);
  const handleClose = (event: {}, reason: 'backdropClick') => {
    if (reason !== 'backdropClick') setOpen(false);
  };
  const handleClickClose = () => {
    setOpen(false);
    localStorage.setItem('updatedLoggedInVersion', newFeaturesVersion);
  };
  const [activeSlide, setActiveSlide] = React.useState(0);
  const WhatsNewSlides = [<Slide1 setClose={handleClickClose} />];
  const maxSlides = WhatsNewSlides.length;
  const handleNext = () => {
    setActiveSlide((prevActiveSlide) => prevActiveSlide + 1);
  };
  const handleBack = () => {
    setActiveSlide((prevActiveSlide) => prevActiveSlide - 1);
  };

  React.useEffect(() => {
    const loggedInVerion: string = localStorage.getItem('updatedLoggedInVersion') || '0.0.0';
    const hidePopup = compare(loggedInVerion, newFeaturesVersion, '<');
    if (showPopup && hidePopup) {
      setOpen(true);
    }
  }, [showPopup, newFeaturesVersion]);
  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      fullScreen={true}
      fullWidth={true}
      sx={{
        '.MuiDialog-paper': {
          backgroundColor: 'primary.main',
          maxWidth: { xs: '100%', md: '600px' },
          maxHeight: { xs: '100%', md: '80%' }
        }
      }}
    >
      <Box sx={{ display: 'flex', p: 3 }}>
        <Button
          onClick={handleBack}
          disabled={activeSlide === 0}
          sx={{
            color: '#ffffff',
            justifyContent: 'flex-start',
            p: 0,
            m: 0,
            alignItems: 'flex-start',
            '&.Mui-disabled': { color: 'primary.main' }
          }}
        >
          <KeyboardArrowLeft />
        </Button>
        <Button
          onClick={handleClickClose}
          sx={{
            marginLeft: 'auto',
            cursor: 'pointer',
            color: '#ffffff',
            minWidth: 'auto'
          }}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Box
        sx={{ height: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column', p: 3, pt: 0 }}
      >
        <Box
          sx={{
            height: '100%',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            '.MuiTypography-root': { color: '#ffffff', mb: 2 }
          }}
        >
          {WhatsNewSlides[activeSlide]}
        </Box>{' '}
        {maxSlides >= 1 && (
          <Box
            sx={{
              marginTop: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Button
              onClick={activeSlide >= maxSlides - 1 ? handleClickClose : handleNext}
              sx={{
                color: 'primary.main',
                backgroundColor: '#ffffff',
                borderRadius: '50px',
                width: '100%',
                mb: 2,
                '&:hover': { color: 'primary.main', backgroundColor: '#ffffff' }
              }}
            >
              {activeSlide === maxSlides - 1 ? 'Close' : 'Next'}
            </Button>

            <MobileStepper
              variant="dots"
              steps={maxSlides}
              position="static"
              activeStep={activeSlide}
              sx={{
                backgroundColor: 'transparent',
                p: 0,
                ' .MuiMobileStepper-dot': {
                  backgroundColor: 'transparent',
                  border: '1px solid #ffffff',
                  '&.MuiMobileStepper-dotActive': {
                    backgroundColor: '#ffffff'
                  }
                }
              }}
              nextButton={null}
              backButton={null}
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export { WhatsNewPopup };
